import { createFlagStore } from '@thyme/libs/src/split/store'

const flagDefinitions = {
  shouldHitBackend: {
    // CLEANUP (long-lived)
    local: true,
    fallback: false,
  },
  enableTimeAwareRefresh: {
    // CLEANUP (SPC-1682)
    local: true,
    fallback: false,
  },
}

export const useFlagStore = createFlagStore(flagDefinitions)
