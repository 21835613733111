<template>
  <ErrorText icon="phone">
    <b>We may not be able to place a call from this device.</b>
    Please call {{ THYME_PHONE_NUMBER_DISPLAY }} on your cell or home phone to
    reach Thyme Care.
  </ErrorText>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { THYME_PHONE_NUMBER_DISPLAY } from '@/libs/thymeInfo'
import ErrorText from '../ErrorText.vue'

export default defineComponent({
  components: {
    ErrorText,
  },
  setup() {
    return {
      THYME_PHONE_NUMBER_DISPLAY,
    }
  },
})
</script>
