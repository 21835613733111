import { createAuthGuard } from '@auth0/auth0-vue'
import { requiredInject } from '@thyme/libs/src/vue/inject'
import { useRoute, RouteLocationGeneric, useRouter } from 'vue-router'
import { useAppLoadStore } from '@/libs/appLoadStore'
import { LoginMethod } from '@/types/auth'
import { APP_CONFIG_KEY } from '@/types/config'

/**
 *
 */
export function setup(): {
  onLoginMethodClick: (connection: LoginMethod) => Promise<void>
  LoginMethod: typeof LoginMethod
  showPlaywrightLogin: boolean
} {
  const route = useRoute()
  const router = useRouter()
  const config = requiredInject(APP_CONFIG_KEY)
  const { startLoading } = useAppLoadStore()

  /**
   * On login method selection
   * @param connection
   */
  async function onLoginMethodClick(connection: LoginMethod) {
    startLoading()
    // Call the auth guard, which will redirect to the Auth0 login page if necessary
    const targetPath =
      route.redirectedFrom?.fullPath ?? (route.query?.redirect as string) ?? '/'
    const authGuard = createAuthGuard({
      redirectLoginOptions: {
        authorizationParams: { connection: connection },
        appState: { target: targetPath },
      },
    })
    const result = await authGuard(
      route.redirectedFrom ??
        ({ fullPath: route.query?.redirect ?? '/' } as RouteLocationGeneric)
    )

    if (result) {
      await router.push(targetPath)
    }
  }

  return {
    onLoginMethodClick,
    LoginMethod,
    showPlaywrightLogin: config.auth0.enablePasswordAuth,
  }
}
