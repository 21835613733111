<template>
  <div class="tcc-error-wrapper">
    <h2 class="flex items-center font-normal">
      <TIcon name="error" :icon="icon" color="nash-neutral000" />
      <span class="pl-2 text-nash-neutral000">
        <slot name="headerText">We're sorry.</slot>
      </span>
    </h2>
    <slot>An unknown error has occurred</slot>
  </div>
</template>

<script lang="ts">
import TIcon from '@thyme/nashville/src/components/icon/TIcon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TIcon,
  },
  props: {
    icon: {
      type: String,
      default: 'libraryError',
    },
  },
})
</script>

<style lang="scss" scoped>
.tcc-error-wrapper {
  @apply text-nash-neutral000;
  @apply rounded-lg;
  @apply p-4;
  background-color: #bc5046;
}
</style>
