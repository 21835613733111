<template>
  <ErrorText icon="textMessage">
    <b>We may not be able to send a text from this device.</b>
    Please type {{ THYME_PHONE_NUMBER_DISPLAY }} into a text-enabled device,
    such as a cell phone, to text Thyme Care.
  </ErrorText>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { THYME_PHONE_NUMBER_DISPLAY } from '@/libs/thymeInfo'
import ErrorText from '../ErrorText.vue'

export default defineComponent({
  components: {
    ErrorText,
  },
  setup() {
    return {
      THYME_PHONE_NUMBER_DISPLAY,
    }
  },
})
</script>
