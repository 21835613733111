import { requiredInject } from '@thyme/libs/src/vue/inject'
import { debounce } from 'lodash'
import { computed, ref } from 'vue'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import { APP_CONFIG_KEY } from '@/types/config'
import { MonitoringEventName } from '@/types/events'
import { getResults } from './queries'
import { SearchResult } from './types'
/**
 *
 */
export function setup() {
  const config = requiredInject(APP_CONFIG_KEY)
  const entityId = useLoggedInEntityId()

  const indexName = 'article'
  const highlightSplitTag = '__THYME-HIGHLIGHT__'

  const suggestions = ref<SearchResult[]>([])
  const isLoading = ref(false)

  const searchIcon = computed(() => (isLoading.value ? 'loading' : 'search'))

  // used to differentiate no search from no results
  const currentQuery = ref('')

  // show "no results found" message
  const showEmpty = computed(
    () => !isLoading.value && !suggestions.value.length && !!currentQuery.value
  )

  const update = async (query: string) => {
    suggestions.value = await getResults(
      config,
      query,
      indexName,
      highlightSplitTag
    )
    isLoading.value = false
  }
  const USER_INPUT_WAIT_TIME = 200
  const debouncedUpdate = debounce(update, USER_INPUT_WAIT_TIME)

  const sendEvent = (query: string) => {
    sendLoggedInMonitoringEvent(
      {
        event: MonitoringEventName.LIBRARY_SEARCH_KEYWORD,
        data: {
          keyword: query,
        },
      },
      entityId
    )
  }

  // Wait 1 second after user stops typing to send event
  const USER_SEARCH_EVENT_WAIT_TIME_MS = 1000
  const onKeyUp = debounce(sendEvent, USER_SEARCH_EVENT_WAIT_TIME_MS)

  const onInput = (query: string) => {
    isLoading.value = true
    currentQuery.value = query
    void debouncedUpdate(query)
    void onKeyUp(query)
  }

  /**
   *
   * @param resourceSlug
   */
  function articleLink(resourceSlug: string) {
    return {
      name: '#libraryPage',
      params: { resourceSlug },
    }
  }

  return {
    searchIcon,
    indexName,
    highlightSplitTag,
    suggestions,
    showEmpty,
    onInput,
    articleLink,
    onKeyUp,
    // for tests only
    currentQuery,
    debouncedUpdate,
  }
}
