import { msToHours } from '@thyme/libs/src/time/convert'
import { ref } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { useFlagStore } from '@/libs/flags'
import { getLogger } from '@/libs/logging'

export const lastAssetLoad = ref(new Date())
export const MAX_HOURS_BETWEEN_ASSET_LOADS = 12
/**
 * Add a guard to reload the page (and assets) if it has been too long since the last load.
 * @param to
 * @param from
 */
export function maybeReloadGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized // eslint-disable-line @typescript-eslint/no-unused-vars
) {
  const now = new Date()
  const hoursSinceLastAssetLoad = msToHours(
    now.getTime() - lastAssetLoad.value.getTime()
  )
  const { enableTimeAwareRefresh } = useFlagStore()
  if (hoursSinceLastAssetLoad > MAX_HOURS_BETWEEN_ASSET_LOADS) {
    if (!enableTimeAwareRefresh) {
      getLogger().info(
        `Skipping reload of page since FF is off (hours since last load (${hoursSinceLastAssetLoad})`
      )
      return true
    }
    getLogger().info(
      `Reloading page to get new assets (hours since last load (${hoursSinceLastAssetLoad})`
    )
    window.location.replace(to.fullPath)
  }
  return true
}
