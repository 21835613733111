import { CONTRACTING_ENTITIES_THEME_CONFIGS } from '../contractingEntities'
import { ALL_PRACTICES_THEME_CONFIGS } from '../practices'

export enum DefaultTheme {
  DEFAULT = 'default',
  AON = 'tcAonFallback',
}

export enum RouteThemeQueryParam {
  PRACTICE = 'practice',
  CONTRACTING_ENTITY = 'ce',
}

export type RouteThemeDefinition = {
  themeQueryParams: RouteThemeQueryParam[] // these should be in priority order
  default: DefaultTheme
}

export const ALL_THEME_CONFIGS = {
  ...ALL_PRACTICES_THEME_CONFIGS,
  ...CONTRACTING_ENTITIES_THEME_CONFIGS,
}
