<template>
  <NavPage>
    <div class="gap-y-6 grid">
      <h1
        data-test="support-header"
        class="text-nash-purple600 font-normal text-2xl"
      >
        <strong>Get Support:</strong> We're here to help.
      </h1>
      <p data-test="support-text" class="text-nash-purple600">
        How would you like to connect?
      </p>
      <div v-if="isMobile" class="gap-y-6 grid">
        <LinkedMobileButton
          v-for="button in buttons"
          :key="button.title"
          :color="button.color"
          :icon="button.icon"
          :description="button.description"
          :title="button.title"
          :link="button.link"
          @click="() => onContactButtonClick(button.method)"
        />
      </div>
      <div v-else class="gap-y-6 grid">
        <a
          class="no-underline"
          :href="`sms:${THYME_PHONE_NUMBER}`"
          @click="onContactButtonClick('sms')"
        >
          <TextError />
        </a>
        <a
          class="no-underline"
          :href="`tel:${THYME_PHONE_NUMBER}`"
          @click="onContactButtonClick('call')"
        >
          <CallError />
        </a>
      </div>
    </div>
  </NavPage>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LinkedMobileButton from '@/components/tokens/LinkedMobileButton/LinkedMobileButton.vue'
import NavPage from '@/components/tokens/NavPage/NavPage.vue'
import CallError from '../tokens/ErrorText/usecases/CallError.vue'
import TextError from '../tokens/ErrorText/usecases/TextError.vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    LinkedMobileButton,
    NavPage,
    TextError,
    CallError,
  },
  setup,
})
</script>
