<template>
  <NavPage>
    <TBreadcrumb :items="breadcrumbs" class="mb-8" />
    <StrapiError v-if="showErrorBlock" data-testid="error-block" />
    <article v-else class="text-left">
      <h1 id="resource-header" class="mt-3 text-nash-purple600 block text-3xl">
        {{ title }}
      </h1>
      <div
        v-if="body"
        id="article-body"
        class="mt-2 leading-6 text-nash-purple600"
      >
        <StrapiBlocks
          v-if="body"
          :modifier="userModifiers"
          :blocks="userBlocks"
          :content="body"
        />
      </div>
    </article>
  </NavPage>
</template>

<script lang="ts">
import TBreadcrumb from '@thyme/nashville/src/components/breadcrumb/TBreadcrumb.vue'
import { defineComponent } from 'vue'
import { StrapiBlocks } from 'vue-strapi-blocks-renderer'
import NavPage from '@/components/tokens/NavPage/NavPage.vue'
import StrapiError from '../tokens/ErrorText/usecases/StrapiError.vue'
import { setup } from './controller'
import { LIBRARY_ARTICLE_PAGE_PROPS } from './types'

export default defineComponent({
  components: {
    NavPage,
    TBreadcrumb,
    StrapiBlocks,
    StrapiError,
  },
  props: LIBRARY_ARTICLE_PAGE_PROPS,
  setup,
})
</script>
