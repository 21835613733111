import { standardScrollBehavior } from '@thyme/libs/src/vue/router'
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import AuthCallback from '@/components/AuthCallback/AuthCallback.vue'
import CommunityResourcesPage from '@/components/CommunityResourcesPage/CommunityResourcesPage.vue'
import ErrorPage from '@/components/ErrorPage/ErrorPage.vue'
import GetSupportPage from '@/components/GetSupportPage/GetSupportPage.vue'
import HomePage from '@/components/HomePage/HomePage.vue'
import LibraryArticlePage from '@/components/LibraryArticlePage/LibraryArticlePage.vue'
import LibraryIndexPage from '@/components/LibraryIndexPage/LibraryIndexPage.vue'
import LoginHelpPage from '@/components/LoginHelpPage/LoginHelpPage.vue'
import LoginMethodPage from '@/components/LoginMethodPage/LoginMethodPage.vue'
import NotFoundPage from '@/components/NotFoundPage/NotFoundPage.vue'

import SurveyPage from '@/components/SurveyPage/SurveyPage.vue'
import SymptomIdPage from '@/components/SymptomIdPage/SymptomIdPage.vue'
import {
  RouteThemeDefinition,
  RouteThemeQueryParam,
  DefaultTheme,
} from '@/types/theme'
import { AUTH_CALLBACK_NAME, maybeAuthGuard } from './auth'
import { maybeReloadGuard } from './autoupdate'
import { pageTitleHook } from './pageTitle'

const DEFAULT_PAGE_TITLE = 'Thyme Care Connect'

export type Route = RouteRecordRaw & {
  name: string
  meta: {
    noAuthRequired?: boolean
    themeDefinition?: RouteThemeDefinition
    title: string
  }
}

export const routes: Route[] = [
  {
    path: '/',
    name: '#home',
    component: HomePage,
    meta: {
      title: DEFAULT_PAGE_TITLE,
    },
  },
  {
    path: '/login-method',
    name: '#loginMethod',
    component: LoginMethodPage,
    meta: {
      noAuthRequired: true,
      title: 'Choose Sign In Method',
    },
  },
  {
    path: '/auth/callback',
    name: AUTH_CALLBACK_NAME,
    component: AuthCallback,
    meta: {
      // The auth callback page needs to be public
      // https://github.com/auth0/auth0-vue/blob/main/FAQ.md#4-getting-an-infinite-redirect-loop-between-my-application-and-auth0
      noAuthRequired: true,
      title: 'Authenticating...',
    },
  },
  {
    path: '/support',
    name: '#support',
    component: GetSupportPage,
    meta: {
      title: 'Get Support',
    },
  },
  {
    path: '/symptoms',
    name: '#symptoms',
    component: SymptomIdPage,
    meta: {
      title: 'Track Symptoms',
    },
  },
  {
    path: '/library',
    name: '#library',
    component: LibraryIndexPage,
    meta: {
      title: 'Library',
    },
  },
  {
    path: '/library/:resourceSlug',
    name: '#libraryPage',
    component: LibraryArticlePage,
    props: true,
    meta: {
      title: 'Library',
    },
  },
  {
    path: '/community',
    name: '#communityResources',
    component: CommunityResourcesPage,
    meta: {
      title: 'Community Resources',
    },
  },
  // public pages
  {
    path: '/aon/surveys',
    name: '#aonSurveys',
    component: SurveyPage,
    meta: {
      noAuthRequired: true,
      title: 'AON Survey',
      themeDefinition: {
        themeQueryParams: [RouteThemeQueryParam.PRACTICE],
        default: DefaultTheme.AON,
      },
    },
  },
  {
    path: '/surveys',
    name: '#surveys',
    component: SurveyPage,
    meta: {
      noAuthRequired: true,
      title: 'Survey',
      themeDefinition: {
        themeQueryParams: [
          RouteThemeQueryParam.PRACTICE,
          RouteThemeQueryParam.CONTRACTING_ENTITY,
        ],
        default: DefaultTheme.DEFAULT,
      },
    },
  },
  {
    path: '/login-help',
    name: '#loginHelp',
    component: LoginHelpPage,
    meta: {
      noAuthRequired: true,
      title: 'Sign In Help',
    },
  },
  {
    path: '/error',
    name: '#error',
    component: ErrorPage,
    meta: {
      noAuthRequired: true,
      title: 'Error',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '#notFound',
    component: NotFoundPage,
    meta: {
      noAuthRequired: true,
      title: 'Page Not Found',
    },
  },
]

/**
 * Creates the TC connect router.
 */
export function getRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: standardScrollBehavior,
  })
  router.beforeEach(maybeReloadGuard)
  router.beforeEach(maybeAuthGuard)
  router.afterEach(pageTitleHook)
  return router
}

export const router = getRouter()
