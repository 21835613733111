import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * Store to keep track of the loading state of the app.
 * intended to show/hide LoadingScreen component.
 */
export const useAppLoadStore = defineStore('appLoadState', () => {
  const isLoading = ref(true)

  const startLoading = () => {
    isLoading.value = true
  }

  const endLoading = () => {
    isLoading.value = false
  }

  return { isLoading, startLoading, endLoading }
})
