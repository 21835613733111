import { useAuth0 } from '@auth0/auth0-vue'
import { unref } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { useAppLoadStore } from '@/libs/appLoadStore'
import { sendLoggedInMonitoringEvent } from '@/libs/events'
import { getLogger } from '@/libs/logging'
import { useLoggedInEntityId } from '@/libs/state/auth0Client'
import { MonitoringEventName } from '@/types/events'

export const AUTH_CALLBACK_NAME = '#authCallback'

/**
 *
 * @param to
 */
function sendTrackingEvent(to: RouteLocationNormalized) {
  try {
    const entityId = useLoggedInEntityId()
    sendLoggedInMonitoringEvent(
      { event: MonitoringEventName.LOGIN, data: { target: to.fullPath } },
      entityId
    )
  } catch (e: any) {
    getLogger().error('Error sending monitoring event', e)
  }
}

/**
 * Add an auth guard unless the route is marked as publicly accessible
 * @param to
 * @param from
 */
export async function maybeAuthGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  const { startLoading, endLoading } = useAppLoadStore()

  if (to.meta.noAuthRequired) {
    // the loading screen shows on first-entry to application
    // this hides it when the first viewed page is one that doesn't require auth
    endLoading()
    return true
  }

  startLoading()
  const client = useAuth0()
  if (!unref(client.isAuthenticated)) {
    // Try to silently auth if we can
    await client.checkSession()
  }
  if (from.name === AUTH_CALLBACK_NAME) {
    sendTrackingEvent(to)
  }

  endLoading()
  if (unref(client.isAuthenticated)) {
    return true
  }
  return {
    name: '#loginMethod',
    query: { redirect: to.fullPath },
  }
}
