<template>
  <ErrorText>
    We're unable to load this information at this time, or this page may not
    exist. Your device or network may also be blocking this page from loading —
    this may be due to a firewall, VPN, or other setting. If you are on a shared
    network, we recommend talking to your IT team. Otherwise, we recommend
    checking your settings. You can always contact Thyme Care for more help at
    <a :href="`tel:${THYME_PHONE_NUMBER}`" class="text-nash-neutral000">{{
      THYME_PHONE_NUMBER_DISPLAY
    }}</a
    >.
  </ErrorText>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  THYME_PHONE_NUMBER,
  THYME_PHONE_NUMBER_DISPLAY,
} from '@/libs/thymeInfo'
import ErrorText from '../ErrorText.vue'

export default defineComponent({
  components: {
    ErrorText,
  },
  setup() {
    return {
      THYME_PHONE_NUMBER,
      THYME_PHONE_NUMBER_DISPLAY,
    }
  },
})
</script>
